<template>
  <div>
    <pageHeader :pagename="'权限组管理'" />

    <el-form :inline="true">

      <el-form-item label="搜索">
        <el-input placeholder="权限组名称" v-model="data.keyword"></el-input>
      </el-form-item>

      <el-form-item style="float:right">
        <el-button type="primary" @click="getTabel">查询</el-button>
      </el-form-item>

    </el-form>

    <el-divider></el-divider>

    <el-row>
        <el-col :span="24">
            <el-table :data="tableData" style="width: 100%">

                <el-table-column prop="id" label="系统ID"  width="100" align="center"> </el-table-column>
                <el-table-column prop="roles_name" label="权限组名"  width="300"> </el-table-column>
                <el-table-column prop="roles_info" label="说明"> </el-table-column>
                <el-table-column prop="create_time" label="创造时间"  width="300"> </el-table-column>

                <el-table-column fixed="right" width="190">
                    <template slot-scope="scope">
                        <!-- <el-button @click="goToedit(scope.row.id,scope.row.roles_name,scope.row.roles_info)" type="primary"
                        >编辑权限（接口暂时未定义）</el-button
                        > -->
                        <el-button @click="goToedit(scope.row.id)">查看权限详情或修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>

  </div>
</template>

<script>
import { personnelAccount_JurisdictionGroupQuery } from "@/api/manage/personnel_account_jurisdiction.js"
export default {
  name: "personnelManagement_JurisdictionManage",
  data() {
    return {
      data: {
        keyword: null,
      },
      tableData: [],
    };
  },
  created() {
    this.getTabel()
  },
  methods:{
    getTabel() {
      this.$http(
        personnelAccount_JurisdictionGroupQuery(this.data),
        (res) => {
            this.tableData =  res.data;
        }
      );
    },
    goToedit(ids,roles_name,roles_info){
      this.$router.push({name:'personnelManagement_JurisdictionManage_Info',params: {"group_id": ids,"roles_name":roles_name,"roles_info":roles_info }})
    }
  },
};
</script>

<style lang="scss" scoped></style>
